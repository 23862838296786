import * as serviceWorkerRegistration from './serviceWorkerRegistration';

let playgroundEl = document.getElementById("playground") as HTMLElement
let georgeEl = document.getElementById("george") as HTMLElement
let controlsEl = document.getElementById("controls") as HTMLElement

let georgeY = playgroundEl.offsetHeight - georgeEl.offsetHeight
let georgeX = (playgroundEl.offsetWidth / 2) - (georgeEl.offsetWidth / 2)
let georgeIsGoingLeftSince: number = -1
let georgeIsGoingLeftX: number = -1
let georgeIsGoingRightSince: number = -1
let georgeIsGoingRightX: number = -1
let georgeSpeedPixelPerSecond = 150

window.onload = (_) => { setTimeout(start, 50) }

setInterval(() => {
  render()
}, 40)

function start() {
  playgroundEl.style.height = controlsEl.getBoundingClientRect().top + "px"
  georgeY = playgroundEl.offsetHeight - georgeEl.offsetHeight
  georgeX = (playgroundEl.offsetWidth / 2) - (georgeEl.offsetWidth / 2)
  georgeEl.style.visibility = "visible"
}

function render() {
  if (georgeIsGoingLeftSince > -1) {
    let time = Date.now() - georgeIsGoingLeftSince
    georgeX = georgeIsGoingLeftX - (time / 1000 * georgeSpeedPixelPerSecond)
    if (georgeX < 0) georgeX = 0
  }
  if (georgeIsGoingRightSince > -1) {
    let time = Date.now() - georgeIsGoingRightSince
    georgeX = georgeIsGoingRightX + (time / 1000 * georgeSpeedPixelPerSecond)
    if (georgeX + georgeEl.offsetWidth > playgroundEl.offsetWidth) georgeX = playgroundEl.offsetWidth - georgeEl.offsetWidth
  }
  renderGeorge()
}

function renderGeorge() {
  georgeEl.style.top = georgeY + "px"
  georgeEl.style.left = georgeX + "px"
}

function georgeStartGoLeft() {
  if (georgeIsGoingLeftSince == -1) {
    georgeIsGoingLeftSince = Date.now()
    georgeIsGoingLeftX = georgeX
    georgeIsGoingRightSince = -1
  }
}

function georgeStopGoLeft() {
  georgeIsGoingLeftSince = -1
}

function georgeStartGoRight() {
  if (georgeIsGoingRightSince == -1) {
    georgeIsGoingRightSince = Date.now()
    georgeIsGoingRightX = georgeX
    georgeIsGoingLeftSince = -1
  }
}

function georgeStopGoRight() {
  georgeIsGoingRightSince = -1
}

document.getElementById("goLeft")?.addEventListener("touchstart", (e) => {
  e.preventDefault()
  georgeStartGoLeft()
})

document.getElementById("goLeft")?.addEventListener("touchend", (e) => {
  e.preventDefault()
  georgeStopGoLeft()
})

document.getElementById("goRight")?.addEventListener("touchstart", (e) => {
  e.preventDefault()
  georgeStartGoRight()
})

document.getElementById("goRight")?.addEventListener("touchend", (e) => {
  e.preventDefault()
  georgeStopGoRight()
})

document.addEventListener("keydown", (e) => {
  e.preventDefault()
  if (e.code == "ArrowLeft") {
    georgeStartGoLeft()
  }
  if (e.code == "ArrowRight") {
    georgeStartGoRight()
  }
})

document.addEventListener("keyup", (e) => {
  e.preventDefault()
  if (e.code == "ArrowLeft") {
    georgeStopGoLeft()
  }
  if (e.code == "ArrowRight") {
    georgeStopGoRight()
  }
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()